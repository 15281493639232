<template>
  <div class="downloads-wrapper">
    <div class="downloads-banner">
      <div class="downloads-banner-des">
        <div>{{ $t('dowanload.h1') }}</div>
        <div>{{ $t('dowanload.h2') }}</div>
        <div>{{ $t('dowanload.des') }}</div>
      </div>
      <div class="downloads-banner-download">
        <div @click="download('https://apps.apple.com/cn/app/ewindow/id1255795701')">
          <img src="@/assets/image/icon/download-phone.png" >
          <span>{{ $t('dowanload.iphonetext') }}</span>
        </div>
        <div  @click="download('http://114.215.176.178:1500/static/other/EWINDOW.rar')">
          <img src="@/assets/image/icon/download-and.png" >
          <span>{{ $t('dowanload.androidtext') }}</span>
        </div>
      </div>
    </div>
    <div class="downloads-main">
      <div class="downloads-main-content">
        <div class="title">{{ $t('relatedDownloads') }}</div>
        <div class="list">
          <div
            v-for="(item, index) in downloadList"
            :key="index"
            @click="downloadFile(item)"
          >
            <div class="icon">
              <img :src="item.icon" />
            </div>
            <div class="text">
              {{ $t(item.prop) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { configDownload } from '@/utils'
export default {
  name: 'Downloads',
  metaInfo() {
    return {
      title: this.$t('downloads')
    }
  },
  data() {
    return {
      downloadactive: 'iphone',
      downloadImg: {
        iphone: require('@/assets/image/resource/dwonlaod-iphone-min.png'),
        android: require('@/assets/image/resource/dwonlaod-anroid-min.png')
      },
      downloadList: configDownload.list
    }
  },
  methods: {
    download(item) {
      if (item) window.open(item)
    },
    downloadFile(item) {
      if(item) window.open(item.link)
    }
  }
}
</script>

<style lang="scss" scoped>
.downloads-wrapper {
  .downloads-banner {
    background: rgba(223, 226, 233, 1);
    height: 300px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &-des {
      & > div {
        &:nth-child(1), &:nth-child(2) {
          text-align: center;
          font-size: 24px;
          line-height: 36px;
          color: rgba(34, 34, 34, 1);
          font-weight: bold;
        }
        &:nth-child(3) {
          margin-top: 10px;
          width: 210px;
          font-size: 13px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }
      }
    }
    &-download {
      display: flex;
      justify-content: center;
      margin-top: 32px;
      & > div {
        width: 143px;
        height: 45px;
        background: #333333;
        border-radius: 23px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        align-items: center;
        justify-content: center;
        display: flex;
        margin: 0 4px;
        img {
          width: 30px;
        }
      }
    }
  }
  .title {
    width: 100%;
    line-height: 36px;
    text-align: center;
    color: rgba(34, 34, 34, 1);
    font-size: 20px;
    font-weight: bold;
    padding: 20px 0;
  }
  .downloads-main {
    width: 100%;
    background: rgba(245, 246, 247, 1);
    padding-bottom: 42px;
    .downloads-main-content {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      .list {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        &>div {
          width: 160px;
          height: 132px;
          cursor: pointer;
          background: #fff;
          transition: all 0.3s;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 13px;
          background: #FFFFFF;
          border-radius: 12px;
          &:last-child {
            margin-right: 0;
          }
          img {
            width: 50px;
            height: 50px;
          }
          &>.text {
            color: rgba(0, 0, 0, 1);
            overflow: hidden;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            color: #222222;
            line-height: 15px;
            margin-top: 18px;
          }
        }
      }
    }
  }
}
</style>